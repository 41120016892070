@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.services {
  @include line;

  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: rem(60);
    gap: 20px;
  }

  &__item {
    position: relative;
    padding-top: rem(148);

    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      margin: 0 auto;
      border-radius: 50%;
      width: 130px;
      height: 130px;
      background-position: center;
      background-size: auto;
      background-repeat: no-repeat;
      background-color: #6c5fff33;
    }

    * {
      text-align: center;
    }

    h3 {
      margin: 0 auto 18px;
      font-weight: var(--fw-400);
      font-size: 24px;
      color: var(--color-purple-600);
    }

    p {
      font-size: 18px;
      line-height: 1.2;
      padding-inline: 4px;
    }

    &--managed {
      &::before {
        background-image: url("../images/icons/managed.svg");
      }
    }

    &--cloud {
      &::before {
        background-image: url("../images/icons/cloud.svg");
      }
    }

    &--cybersecurity {
      &::before {
        background-image: url("../images/icons/cybersecurity.svg");
      }
    }

    &--data {
      &::before {
        background-image: url("../images/icons/data.svg");
      }
    }
  }

  .btn {
    margin: 0 auto;
    max-width: 230px;
  }

  @include media(tablet) {
    &__grid {
      grid-template-columns: repeat(2, 1fr);
    }

    &__item {
      padding-top: rem(120);

      &::before {
        width: 100px;
        height: 100px;
      }
    }
  }

  @include media(mobile-m) {
    &__grid {
      grid-template-columns: 1fr;
      gap: 50px;
    }
  }
}