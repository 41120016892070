@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.insights {
  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0 auto;
    width: 100%;
    max-width: 822px;
    gap: rem(48) 24px;
  }

  h2 {
    margin-bottom: 7px;
    letter-spacing: -0.01em;
    text-align: center;
  }

  .card {
    max-width: 100%;

    img {
      width: 100%;
    }
  }

  @include media(mobile-m) {
    &__grid {
      grid-template-columns: 1fr;
    }

    img {
      max-height: 250px;
    }
  }
}

section.insights {
  padding-top: calc(var(--header-height) + rem(80));
}