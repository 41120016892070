@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

html,
body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  height: 100dvh;
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-400);
  font-style: normal;
  font-size: clamp(16px, 1vw, 20px);
  line-height: 1.5;
  letter-spacing: 0;
  color: var(--color-dark);
  background-color: var(--color-white);
  font-optical-sizing: auto;

  &__body {
    min-width: 320px;
    min-height: var(--vh);
  }

  &.open {
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      background-color: var(--color-black);
      opacity: 0.2;
    }
  }
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
}

.site-container {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr auto;
  overflow-x: hidden;
  min-height: var(--vh);
}

.logo {
  display: block;
  margin-right: rem(39);
  width: 100%;
  max-width: 303px;

  img {
    width: 100%;
  }

  @include media(tablet-wide) {
    margin-right: 20px;
    max-width: 220px;
  }

  @include media(tablet) {
    margin-right: 20px;
    max-width: 250px;
  }
}

.background {
  background-color: #6C5FFF33;
}

.overlay {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    background: var(--gradient);
    inset: 0;
  }
}

.section-header {
  margin-bottom: rem(53);

  h2 {
    margin: 0 auto 8px;
    color: var(--color-black);
  }

  p {
    font-weight: var(--fw-700);
    font-size: 24px;
    letter-spacing: -0.02em;
    text-align: center;
  }
}

section:not(.hero, .blog) {
  position: relative;
  padding: rem(80) 0;
}

.back {
  position: relative;
  display: block;
  margin-bottom: 10px;
  padding-left: 28px;
  text-transform: capitalize;
  color: #4e45b4;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 2px;
    width: 17px;
    height: 17px;
    background-image: var(--arrow);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    filter: brightness(0) saturate(100%) invert(24%) sepia(88%) saturate(1926%) hue-rotate(234deg) brightness(80%) contrast(83%);
    scale: -1 1;
  }
}