@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.testimonials {
  $root: &;

  @include line;

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 26px;
  }

  li {
    font-weight: var(--fw-400);

    img {
      margin: 0 auto 22px;
    }

    h3 {
      margin: 0 auto 6px;
      font-weight: var(--fw-400);
      font-size: 18px;
    }

    p {
      margin-bottom: 27px;
      font-size: 14px;
      letter-spacing: 0.14em;
      text-align: center;
      color: var(--color-purple-400);
    }

    blockquote {
      font-weight: var(--fw-600);
      font-size: 18px;
      line-height: 1.55;
    }
  }

  &--team {
    #{$root}__grid {
      gap: 22px;

      h3 {
        margin-left: 0;
      }

      p {
        text-align: left;
        letter-spacing: 0.03em;
      }
    }
  }

  @include media(tablet) {
    &__grid {
      grid-template-columns: 1fr;
    }

    &--team {
      #{$root}__grid {
        h3 {
          margin: 0 auto 20px;
        }

        p {
          text-align: center;
        }
      }
    }
  }
}