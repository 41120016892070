@import "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap";
:root {
  --content-width: 1244px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width)  + (var(--container-offset) * 2));
  --font-family-primary: "Open Sans", sans-serif;
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-800: 800;
  --color-white: #fff;
  --color-black: #030022;
  --color-dark: #03002266;
  --color-dark-100: #03002299;
  --color-white-100: #fff9;
  --color-purple-200: #6c5fff99;
  --color-purple-400: #6c5fff;
  --color-purple-600: #0c0094;
  --radius-main: 2px;
  --gradient: linear-gradient(0deg, #0003, #0003), linear-gradient(0deg, #6c5fff0f, #6c5fff0f);
  --gradient-border: linear-gradient(90deg, #fff0 0%, #fff 50.48%, #fff0 100%);
  --arrow: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.125 10H16.875' stroke='white' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11.25 4.375L16.875 10L11.25 15.625' stroke='white' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.checkbox__field:checked + .checkbox__content:after {
  opacity: 1;
}

.checkbox__field:focus + .checkbox__content:before {
  outline: 1px solid var(--color-gray-100);
  outline-offset: 1px;
}

.checkbox__field:disabled + .checkbox__content {
  opacity: .4;
  pointer-events: none;
}

html, body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  height: 100dvh;
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-400);
  letter-spacing: 0;
  color: var(--color-dark);
  background-color: var(--color-white);
  font-optical-sizing: auto;
  font-size: clamp(16px, 1vw, 20px);
  font-style: normal;
  line-height: 1.5;
}

.page__body {
  min-width: 320px;
  min-height: var(--vh);
}

.page.open {
  overflow: hidden;
}

.page.open:before {
  content: "";
  background-color: var(--color-black);
  opacity: .2;
  position: absolute;
  inset: 0;
}

.container {
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
  margin: 0 auto;
}

.site-container {
  min-height: var(--vh);
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
  display: grid;
  overflow-x: hidden;
}

.logo {
  width: 100%;
  max-width: 303px;
  margin-right: 2.4375rem;
  display: block;
}

.logo img {
  width: 100%;
}

@media only screen and (max-width: 1180px) {
  .logo {
    max-width: 220px;
    margin-right: 20px;
  }
}

@media only screen and (max-width: 992px) {
  .logo {
    max-width: 250px;
    margin-right: 20px;
  }
}

.background {
  background-color: #6c5fff33;
}

.overlay {
  position: relative;
}

.overlay:before {
  content: "";
  background: var(--gradient);
  position: absolute;
  inset: 0;
}

.section-header {
  margin-bottom: 3.3125rem;
}

.section-header h2 {
  color: var(--color-black);
  margin: 0 auto 8px;
}

.section-header p {
  font-weight: var(--fw-700);
  letter-spacing: -.02em;
  text-align: center;
  font-size: 24px;
}

section:not(.hero, .blog) {
  padding: 5rem 0;
  position: relative;
}

.back {
  text-transform: capitalize;
  color: #4e45b4;
  margin-bottom: 10px;
  padding-left: 28px;
  display: block;
  position: relative;
}

.back:before {
  content: "";
  width: 17px;
  height: 17px;
  background-image: var(--arrow);
  filter: brightness(0) saturate() invert(24%) sepia(88%) saturate(1926%) hue-rotate(234deg) brightness(80%) contrast(83%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 2px;
  left: 0;
  scale: -1 1;
}

.hidden {
  display: none !important;
}

.sr-only {
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  width: 1px !important;
  height: 1px !important;
  white-space: nowrap !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.dis-scroll {
  width: 100%;
  height: 100dvh;
  height: calc(var(--vh, 1vh) * 100);
  overscroll-behavior: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.is-scrolling {
  scrollbar-color: var(--color-purple-400) var(--color-white);
  scrollbar-width: thin;
}

.is-scrolling::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.is-scrolling::-webkit-scrollbar-track {
  background-color: var(--color-white);
  border-radius: 10px;
}

.is-scrolling::-webkit-scrollbar-thumb {
  background-color: var(--color-purple-400);
  border-radius: 10px;
}

@media only screen and (max-width: 1180px) {
  .is-scrolling {
    padding: 15px;
    overflow-y: scroll;
  }
}

h1, h2, h3 {
  width: -moz-fit-content;
  width: fit-content;
  line-height: 1.3;
  overflow: hidden;
}

h1 {
  line-height: 1.1;
  font-weight: var(--fw-800);
  font-size: clamp(2.125rem, 1.4286rem + 2.8571vi, 4rem);
}

@media only screen and (min-width: 1441px) {
  h1 {
    font-size: 64px;
  }
}

h2 {
  font-weight: var(--fw-700);
  color: var(--color-black);
  font-size: clamp(1.6875rem, 1.3857rem + 1.2381vi, 2.5rem);
}

h3 {
  font-weight: var(--fw-700);
  color: var(--color-black);
  font-size: 20px;
}

p {
  font-size: 18px;
}

.header {
  z-index: 10;
  border-bottom: 1px solid;
  border-image-source: linear-gradient(90deg, #fff0 0%, var(--color-white) 50.48%, #fff0 100%);
  width: 100%;
  border-image-slice: 1;
  margin: 0 auto;
  padding: 24px 0;
  transition-property: background-color, transform, padding;
  transition-duration: .15s;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.header.hide {
  transform: translateY(calc(-100% - 30px));
}

.header.scroll {
  background-color: var(--color-white);
}

.header.scroll .logo {
  filter: brightness(0);
}

.header.scroll .nav__link {
  color: var(--color-black);
}

.header.scroll .nav__link.true {
  color: var(--color-purple-400);
}

.header.scroll .burger {
  --lines-color: var(--color-black);
}

.header.hide.scroll {
  transform: translateY(0);
}

.header--secondary {
  color: var(--color-white);
  background-color: var(--color-purple-400);
}

.header--secondary .nav__link.true {
  color: var(--color-white);
  text-decoration: underline;
}

.header--secondary button.formsapp-popup-button {
  border-color: var(--color-white) !important;
}

.nav {
  align-items: center;
  margin-left: auto;
  display: flex;
  position: relative;
}

.nav__inner {
  width: 100%;
  justify-content: flex-end;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .header .nav__inner {
    z-index: -1;
    padding-top: calc(var(--header-height) * 1.5);
    width: 100%;
    height: calc(var(--vh));
    max-width: 340px;
    background-color: var(--color-white);
    flex-direction: column;
    justify-content: flex-start;
    padding-inline: 10px;
    transition-property: transform;
    transition-duration: .4s;
    position: fixed;
    top: 0;
    right: 0;
    overflow-y: auto;
    transform: translateX(150%);
  }
}

.nav__list {
  align-items: center;
  margin-right: 25px;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .header .nav__list {
    z-index: -1;
    flex-direction: column;
  }
}

.nav__item:not(:last-child) {
  margin-right: 17px;
}

@media only screen and (max-width: 992px) {
  .nav__item:not(:last-child) {
    margin-right: 0;
  }

  .header .nav__item {
    margin-bottom: 30px;
  }
}

.nav__link {
  font-weight: var(--fw-600);
  white-space: nowrap;
  color: var(--color-white);
  background-color: #0000;
  padding: 0 10px;
  font-size: 18px;
  transition: color .2s;
}

.nav__link:hover, .nav__link:focus {
  text-decoration: underline;
}

.nav__link.true {
  color: var(--color-purple-400);
}

.nav__btns {
  width: 100%;
  max-width: 330px;
  justify-content: space-between;
  display: flex;
}

.nav__btns button.formsapp-popup-button {
  max-width: 183px;
}

@media only screen and (max-width: 992px) {
  .nav__btns {
    flex-direction: column;
    gap: 30px;
  }
}

.nav--footer {
  display: flex;
}

@media only screen and (max-width: 992px) {
  .nav__list {
    margin: 0 auto;
  }

  .nav__link {
    color: var(--color-black);
  }

  .nav__link.true {
    color: var(--color-purple-400) !important;
  }

  .nav__btns {
    align-items: center;
  }

  .nav button.formsapp-popup-button {
    padding: 20px 0 !important;
  }
}

[class].btn {
  width: 100%;
  max-width: 130px;
  font-weight: var(--fw-600);
  text-align: center;
  color: var(--color-black);
  background-color: var(--color-white);
  border: 1px solid #0000;
  justify-content: center;
  align-items: center;
  line-height: 1.28;
  transition-property: background-color, color, border-color;
  transition-duration: .2s;
  display: flex;
  position: relative;
  border-radius: calc(var(--radius-main) * 50) !important;
  padding: 8px 15px !important;
  font-size: 18px !important;
}

[class].btn:hover, [class].btn:focus {
  border-color: var(--color-purple-400);
  color: var(--color-purple-400);
}

[class].btn--reverse {
  color: var(--color-white);
  background-color: var(--color-purple-400);
}

[class].btn--reverse:hover, [class].btn--reverse:focus {
  border-color: var(--color-purple-400);
  color: var(--color-purple-400);
  background-color: var(--color-white);
}

[class].btn--dark {
  color: var(--color-white);
  background-color: var(--color-purple-400);
}

[class].btn--arrow:after {
  content: "";
  width: 20px;
  height: 20px;
  background-image: var(--arrow);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-shrink: 0;
  margin-left: 8px;
}

button.formsapp-popup-button {
  white-space: nowrap;
  font-family: var(--font-family-primary) !important;
  color: var(--color-white) !important;
  background-color: var(--color-purple-400) !important;
  margin: 0 !important;
  padding: 0 !important;
  line-height: 1 !important;
}

button.formsapp-popup-button:hover, button.formsapp-popup-button:focus {
  border-color: var(--color-white) !important;
}

.burger {
  --line-height: 2px;
  --buger-size: 20px;
  --move: calc(var(--buger-size) / 3);
  --move-decrement: calc(var(--move) * -1);
  --transition-time: .5s;
  --lines-color: var(--color-white);
  width: calc(var(--buger-size)  + 5px);
  height: var(--buger-size);
  transition: opacity var(--transition-time) ease;
  background-color: #0000;
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.burger span {
  z-index: -1;
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  transition: transform var(--transition-time) ease, background-color var(--transition-time) ease .1s;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.burger span:before, .burger span:after {
  content: "";
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  animation-duration: .4s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
  position: absolute;
  left: 0;
}

.burger span:before {
  transform: translateY(var(--move-decrement)) rotate(0);
}

.burger span:after {
  transform: translateY(var(--move)) rotate(0);
}

.burger:hover {
  opacity: 1;
}

@media only screen and (max-width: 992px) {
  .burger {
    display: block;
  }
}

.closed.opened span {
  background-color: var(--lines-color);
  transition: background-color .2s;
}

.closed.opened span:before {
  animation-name: toplineburger;
}

.closed.opened span:after {
  animation-name: bottomlineburger;
}

.opened .nav__inner {
  transform: translateX(0);
}

.opened span {
  background-color: #0000;
  transition: background-color .2s;
}

.opened span:before, .opened span:after {
  animation-duration: .2s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
}

.opened span:before {
  animation-name: toplinecross;
}

.opened span:after {
  animation-name: bottomlinecross;
}

@keyframes toplinecross {
  0% {
    transform: translateY(var(--move-decrement));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes bottomlinecross {
  0% {
    transform: translateY(var(--move));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0)rotate(-45deg);
  }
}

@keyframes toplineburger {
  0% {
    transform: rotate(45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move-decrement)) rotate(0);
  }
}

@keyframes bottomlineburger {
  0% {
    transform: translateY(0)rotate(-45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move)) rotate(0);
  }
}

.policy__content {
  margin-bottom: 3rem;
}

.policy h1 {
  color: var(--color-black);
  margin: 0 auto 3rem;
  position: relative;
}

.policy h2 {
  font-weight: var(--fw-600);
  text-align: left;
  color: #4e45b4;
  margin-bottom: 24px;
  font-size: 30px;
  line-height: 1.3;
}

.policy ul {
  margin-bottom: 24px;
  padding-left: 15px;
}

.policy li {
  padding-left: 5px;
  font-size: 20px;
  line-height: 1.43;
  position: relative;
}

.policy li:before {
  content: "✓";
  position: absolute;
  left: -18px;
}

.policy li:not(:last-child) {
  margin-bottom: 3px;
}

.policy p {
  font-size: 20px;
  line-height: 1.2;
}

.policy p:not(:last-child) {
  margin-bottom: 30px;
}

.policy a {
  color: var(--color-purple-400);
  text-decoration: underline;
}

.policy b, .policy strong {
  font-weight: var(--fw-600);
}

@media only screen and (max-width: 768px) {
  .policy .container {
    padding: 40px 0;
  }

  .policy h1 {
    text-align: center;
    margin-bottom: 30px;
  }
}

section.policy {
  padding-top: calc(var(--header-height)  + 5rem);
}

.footer {
  color: var(--color-white);
  background-color: var(--color-purple-400);
  padding: 2.625rem 0 30px;
}

.footer__inner {
  justify-content: space-between;
  display: flex;
}

.footer__nav {
  translate: 0 -5px;
}

.footer__nav li:not(:last-child) {
  margin-bottom: 10px;
}

.footer__nav a {
  line-height: 1;
}

.footer p {
  font-size: 16px;
}

.footer .logo {
  margin-bottom: 11px;
}

.footer .mail {
  margin-bottom: 2.125rem;
  padding-left: 32px;
  font-size: 16px;
  display: block;
  position: relative;
}

.footer .mail:before {
  content: "";
  width: 20px;
  height: 16px;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM18 14H2V4L10 9L18 4V14ZM10 7L2 2H18L10 7Z' fill='white' fill-opacity='0.6'/%3E%3C/svg%3E%0A");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 2px;
  left: 2px;
}

@media only screen and (max-width: 768px) {
  .footer__inner {
    flex-direction: column;
    align-items: center;
  }

  .footer__inner > div {
    text-align: center;
  }

  .footer__inner > div:not(:last-child) {
    margin-bottom: 20px;
  }

  .footer .logo {
    margin: 0 auto 20px;
  }

  .footer .mail {
    max-width: max-content;
    margin: 0 auto 20px;
  }
}

.hero {
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  display: flex;
}

.hero__inner {
  z-index: 1;
  width: 100%;
  min-height: 100dvh;
  max-width: 1060px;
  text-align: center;
  color: var(--color-white);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.hero__inner h1 {
  margin-bottom: 10px;
  padding-top: 12px;
}

.hero__inner p {
  font-weight: var(--fw-600);
  font-size: 24px;
}

.contact {
  position: relative;
}

.contact__inner {
  z-index: 1;
  border-radius: calc(var(--radius-main)  + 4px);
  width: 100%;
  max-width: 1008px;
  background-color: var(--color-white);
  border: 1px solid #0000003d;
  margin: 0 auto;
  padding: 3.5625rem 3.3125rem 3.125rem;
  position: relative;
  overflow: hidden;
}

.contact h2 {
  letter-spacing: .02em;
  margin-bottom: 15px;
  font-size: 36px;
}

.contact p {
  letter-spacing: .02em;
  color: #32323299;
  margin-bottom: 3.3125rem;
  font-size: 22px;
  line-height: 1.3;
}

.contact label {
  display: block;
}

.contact input, .contact textarea {
  border-radius: calc(var(--radius-main) * 5);
  width: 100%;
  color: #32323299;
  background-color: #0000;
  border: 1px solid #d8d8d8;
  padding: 17px 24px 17px 53px;
}

.contact input::placeholder, .contact textarea::placeholder {
  color: #32323299;
}

.contact .mail {
  font-weight: var(--fw-700);
  margin-bottom: 26px;
  font-size: 14px;
  display: block;
  position: relative;
}

.contact .mail:before {
  content: "";
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM18 14H2V4L10 9L18 4V14ZM10 7L2 2H18L10 7Z' fill='%23323232' fill-opacity='0.8'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  left: 2px;
}

.contact .profile {
  font-weight: var(--fw-700);
  margin-bottom: 28px;
  font-size: 14px;
  display: block;
  position: relative;
}

.contact .profile:before {
  content: "";
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM5.35 16.5C6.66 15.56 8.26 15 10 15C11.74 15 13.34 15.56 14.65 16.5C13.34 17.44 11.74 18 10 18C8.26 18 6.66 17.44 5.35 16.5ZM16.14 15.12C14.45 13.8 12.32 13 10 13C7.68 13 5.55 13.8 3.86 15.12C2.7 13.73 2 11.95 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 11.95 17.3 13.73 16.14 15.12Z' fill='%23323232' fill-opacity='0.8'/%3E%3Cpath d='M10 4C8.07 4 6.5 5.57 6.5 7.5C6.5 9.43 8.07 11 10 11C11.93 11 13.5 9.43 13.5 7.5C13.5 5.57 11.93 4 10 4ZM10 9C9.17 9 8.5 8.33 8.5 7.5C8.5 6.67 9.17 6 10 6C10.83 6 11.5 6.67 11.5 7.5C11.5 8.33 10.83 9 10 9Z' fill='%23323232' fill-opacity='0.8'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: -2px;
  left: 2px;
}

.contact a.mail {
  padding-left: 35px;
}

.contact .profile:before, .contact label.mail:before {
  top: 20px;
  left: 22px;
}

.contact label.mail {
  margin-bottom: 3.4375rem;
}

.contact .btn {
  max-width: 225px;
  padding: 17px;
}

@media only screen and (max-width: 576px) {
  .contact__inner {
    padding: 20px;
  }

  .contact h2 {
    font-size: 30px;
  }

  .contact a.mail {
    padding-left: 0;
  }

  .contact a.mail:before {
    display: none;
  }
}

section.contact {
  padding: 7.75rem 0 5rem;
}

.services {
  position: relative;
}

.services:before {
  content: "";
  height: 1px;
  max-width: var(--content-width);
  background-color: #6c5fff3d;
  margin: 0 auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.services__grid {
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 3.75rem;
  display: grid;
}

.services__item {
  padding-top: 9.25rem;
  position: relative;
}

.services__item:before {
  content: "";
  width: 130px;
  height: 130px;
  background-color: #6c5fff33;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  border-radius: 50%;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.services__item * {
  text-align: center;
}

.services__item h3 {
  font-weight: var(--fw-400);
  color: var(--color-purple-600);
  margin: 0 auto 18px;
  font-size: 24px;
}

.services__item p {
  padding-inline: 4px;
  font-size: 18px;
  line-height: 1.2;
}

.services__item--managed:before {
  background-image: url("../images/icons/managed.svg");
}

.services__item--cloud:before {
  background-image: url("../images/icons/cloud.svg");
}

.services__item--cybersecurity:before {
  background-image: url("../images/icons/cybersecurity.svg");
}

.services__item--data:before {
  background-image: url("../images/icons/data.svg");
}

.services .btn {
  max-width: 230px;
  margin: 0 auto;
}

@media only screen and (max-width: 992px) {
  .services__grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .services__item {
    padding-top: 7.5rem;
  }

  .services__item:before {
    width: 100px;
    height: 100px;
  }
}

@media only screen and (max-width: 576px) {
  .services__grid {
    grid-template-columns: 1fr;
    gap: 50px;
  }
}

.testimonials {
  position: relative;
}

.testimonials:before {
  content: "";
  height: 1px;
  max-width: var(--content-width);
  background-color: #6c5fff3d;
  margin: 0 auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.testimonials__grid {
  grid-template-columns: repeat(3, 1fr);
  gap: 26px;
  display: grid;
}

.testimonials li {
  font-weight: var(--fw-400);
}

.testimonials li img {
  margin: 0 auto 22px;
}

.testimonials li h3 {
  font-weight: var(--fw-400);
  margin: 0 auto 6px;
  font-size: 18px;
}

.testimonials li p {
  letter-spacing: .14em;
  text-align: center;
  color: var(--color-purple-400);
  margin-bottom: 27px;
  font-size: 14px;
}

.testimonials li blockquote {
  font-weight: var(--fw-600);
  font-size: 18px;
  line-height: 1.55;
}

.testimonials--team .testimonials__grid {
  gap: 22px;
}

.testimonials--team .testimonials__grid h3 {
  margin-left: 0;
}

.testimonials--team .testimonials__grid p {
  text-align: left;
  letter-spacing: .03em;
}

@media only screen and (max-width: 992px) {
  .testimonials__grid {
    grid-template-columns: 1fr;
  }

  .testimonials--team .testimonials__grid h3 {
    margin: 0 auto 20px;
  }

  .testimonials--team .testimonials__grid p {
    text-align: center;
  }
}

.card {
  max-width: 300px;
}

.card img {
  border-radius: var(--radius-main);
  width: 100%;
  max-height: 182px;
  margin-bottom: 15px;
  overflow: hidden;
}

.card time, .card span {
  letter-spacing: .15em;
  color: var(--color-purple-200);
  font-size: 14px;
}

.card h3 {
  letter-spacing: -.02em;
  margin-bottom: 20px;
  font-size: 20px;
}

.card a {
  letter-spacing: .02em;
  color: var(--color-purple-400);
  font-size: 14px;
  text-decoration: underline;
}

.card--large {
  max-width: 100%;
}

.card--large img {
  max-height: 100%;
}

.card--large span {
  margin-bottom: 15px;
  display: block;
}

.card--large ul {
  margin-bottom: 2.8125rem;
  padding-left: 24px;
}

.card--large ul li {
  color: var(--color-black);
  letter-spacing: .006em;
  line-height: 1.47;
  list-style-type: disc;
}

.card--large p {
  font-weight: var(--fw-600);
  font-size: 18px;
  line-height: 1.57;
}

.blog {
  padding: 5rem 0;
}

.blog__grid {
  grid-template-columns: repeat(4, 1fr);
  gap: 3.0625rem 23px;
  display: grid;
}

.blog h2 {
  text-align: center;
}

@media only screen and (max-width: 992px) {
  .blog__grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .blog .card {
    max-width: 100%;
  }
}

@media only screen and (max-width: 576px) {
  .blog__grid {
    grid-template-columns: 1fr;
  }
}

.single {
  padding: calc(var(--header-height)  + 4.75rem) 0 5rem;
}

.single .container {
  max-width: 640px;
}

.single :not(:last-child, .back, h1, time, li, h2 + p) {
  margin-bottom: 24px;
}

.single h1 {
  font-weight: var(--fw-700);
  letter-spacing: -.01em;
  color: var(--color-black);
  margin-bottom: 13px;
  font-size: clamp(1.625rem, 1.2536rem + 1.5238vi, 2.625rem);
  line-height: 1.2;
}

.single time {
  font-weight: var(--fw-700);
  letter-spacing: 2px;
  color: var(--color-purple-200);
  margin-bottom: 2.5rem;
  font-size: 14px;
  display: block;
}

.single p {
  font-weight: var(--fw-400);
  font-size: 16px;
  line-height: 1.64;
}

.single h2 + p {
  margin-bottom: 25px;
}

.single ol {
  padding-left: 22px;
  list-style: auto;
}

.single ol li {
  list-style: auto;
}

.single ol li::marker {
  font-weight: var(--fw-700);
  color: var(--color-purple-400);
}

.single ol li span {
  font-weight: var(--fw-700);
  color: var(--color-purple-400);
}

.single ul {
  padding-left: 22px;
}

.single ul li {
  list-style-type: disc;
}

.single h2 {
  color: var(--color-purple-400);
  font-size: 16px;
}

.single h2 + ol li::marker, .single p + ol li::marker {
  font-weight: var(--fw-400);
  color: inherit;
}

.choose {
  position: relative;
}

.choose:before {
  content: "";
  height: 1px;
  max-width: var(--content-width);
  background-color: #6c5fff3d;
  margin: 0 auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.choose__list {
  grid-template-columns: repeat(3, 1fr);
  gap: 28px;
  display: grid;
}

.choose h3 {
  text-transform: uppercase;
  margin-bottom: 18px;
  font-size: 18px;
}

.choose img {
  margin-bottom: 22px;
}

@media only screen and (max-width: 992px) {
  .choose__list {
    grid-template-columns: 1fr;
  }
}

.about__inner {
  align-items: center;
  display: flex;
}

.about__image {
  width: 100%;
  max-width: 610px;
  margin-right: 25px;
}

.about__content {
  max-width: 595px;
  margin-right: auto;
}

.about h2 {
  margin-bottom: 30px;
}

.about p {
  line-height: 1.55;
}

.about--reverse .about__inner {
  flex-direction: row-reverse;
}

.about--reverse .about__image {
  margin-right: 17px;
}

.about--reverse h2 {
  margin-bottom: 8px;
}

@media only screen and (max-width: 992px) {
  .about--reverse .about__inner, .about__inner {
    flex-direction: column;
  }

  .about__image {
    max-width: 100%;
    margin: 0 auto 30px;
  }

  .about__image img {
    aspect-ratio: 16 / 7;
    width: 100%;
  }

  .about__content {
    max-width: 100%;
  }
}

.about-list__grid {
  grid-template-columns: repeat(5, 1fr);
  gap: 30px;
  display: grid;
}

.about-list__item {
  padding-top: 5.5rem;
  position: relative;
}

.about-list__item:before {
  content: "";
  width: 64px;
  height: 64px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0;
}

.about-list__item--customer:before {
  background-image: url("../images/icons/customer.svg");
}

.about-list__item--innovation:before {
  background-image: url("../images/icons/innovation.svg");
}

.about-list__item--integrity:before {
  background-image: url("../images/icons/integrity.svg");
}

.about-list__item--collaboration:before {
  background-image: url("../images/icons/collaboration.svg");
}

.about-list__item--learning:before {
  background-image: url("../images/icons/learning.svg");
}

.about-list h3 {
  font-weight: var(--fw-600);
  margin-bottom: 14px;
  font-size: 18px;
  line-height: 1.2;
}

.about-list p {
  font-size: 14px;
}

@media only screen and (max-width: 992px) {
  .about-list__grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .about-list__item:last-child {
    grid-column: span 2;
  }
}

@media only screen and (max-width: 576px) {
  .about-list__grid {
    grid-template-columns: 1fr;
  }

  .about-list__item:last-child {
    grid-column: auto;
  }
}

.approach__grid {
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  display: grid;
}

.approach li {
  width: 100%;
  max-width: 400px;
  border-left: 1px solid #0300223d;
  padding: 0 24px;
}

.approach li h3 {
  font-weight: var(--fw-400);
  letter-spacing: -.05em;
  margin-bottom: 20px;
}

.approach li p {
  letter-spacing: .017em;
  line-height: 1.55;
}

.approach .section-header h2 {
  margin-bottom: 5px;
}

.approach .section-header p {
  margin-bottom: 3.25rem;
}

@media only screen and (max-width: 992px) {
  .approach__grid {
    grid-template-columns: 1fr;
  }

  .approach li {
    max-width: 100%;
  }
}

section.approach {
  padding-bottom: 3.25rem;
}

.insights__grid {
  width: 100%;
  max-width: 822px;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem 24px;
  margin: 0 auto;
  display: grid;
}

.insights h2 {
  letter-spacing: -.01em;
  text-align: center;
  margin-bottom: 7px;
}

.insights .card {
  max-width: 100%;
}

.insights .card img {
  width: 100%;
}

@media only screen and (max-width: 576px) {
  .insights__grid {
    grid-template-columns: 1fr;
  }

  .insights img {
    max-height: 250px;
  }
}

section.insights {
  padding-top: calc(var(--header-height)  + 5rem);
}

.offerings {
  padding: 6.0625rem 0;
}

.offerings__tabs {
  justify-content: center;
  margin-bottom: 2.1875rem;
  display: flex;
}

.offerings__tabs li:not(:last-child) {
  margin-right: 2.75rem;
}

.offerings__grid {
  grid-template-columns: repeat(3, 1fr);
  gap: 2.625rem 24px;
  display: grid;
}

.offerings .section-header {
  margin-bottom: 2rem;
}

.offerings button {
  font-weight: var(--fw-600);
  white-space: nowrap;
  color: var(--color-purple-400);
  background-color: #0000;
  padding: 0;
  font-size: 20px;
  line-height: 1;
}

.offerings button:hover, .offerings button:focus {
  border-color: #0000;
}

.offerings button.active {
  font-weight: var(--fw-700);
  border-color: #0000;
  font-size: 24px;
}

@media only screen and (max-width: 992px) {
  .offerings__grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .offerings__tabs {
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 576px) {
  .offerings__grid {
    grid-template-columns: 1fr;
  }

  .offerings__tabs li:not(:last-child) {
    margin-right: 20px;
  }
}

/*# sourceMappingURL=main.css.map */
