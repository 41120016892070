@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.contact {
  position: relative;

  &__inner {
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin: 0 auto;
    border: 1px solid #0000003D;
    border-radius: calc(var(--radius-main) + 4px);
    padding: rem(57) rem(53) rem(50);
    width: 100%;
    max-width: 1008px;
    background-color: var(--color-white);
  }

  h2 {
    margin-bottom: 15px;
    font-size: 36px;
    letter-spacing: 0.02em;
  }

  p {
    margin-bottom: rem(53);
    font-size: 22px;
    line-height: 1.3;
    letter-spacing: 0.02em;
    color: #32323299;
  }

  label {
    display: block;
  }

  input,
  textarea {
    border: 1px solid #D8D8D8;
    border-radius: calc(var(--radius-main) * 5);
    padding: 17px 24px 17px 53px;
    width: 100%;
    color: #32323299;
    background-color: transparent;

    &::placeholder {
      color: #32323299;
    }
  }

  .mail {
    position: relative;
    display: block;
    margin-bottom: 26px;
    font-weight: var(--fw-700);
    font-size: 14px;

    &::before {
      content: "";
      position: absolute;
      left: 2px;
      top: 0;
      width: 20px;
      height: 20px;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM18 14H2V4L10 9L18 4V14ZM10 7L2 2H18L10 7Z' fill='%23323232' fill-opacity='0.8'/%3E%3C/svg%3E%0A");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  .profile {
    position: relative;
    display: block;
    margin-bottom: 28px;
    font-weight: var(--fw-700);
    font-size: 14px;

    &::before {
      content: "";
      position: absolute;
      left: 2px;
      top: -2px;
      width: 20px;
      height: 20px;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM5.35 16.5C6.66 15.56 8.26 15 10 15C11.74 15 13.34 15.56 14.65 16.5C13.34 17.44 11.74 18 10 18C8.26 18 6.66 17.44 5.35 16.5ZM16.14 15.12C14.45 13.8 12.32 13 10 13C7.68 13 5.55 13.8 3.86 15.12C2.7 13.73 2 11.95 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 11.95 17.3 13.73 16.14 15.12Z' fill='%23323232' fill-opacity='0.8'/%3E%3Cpath d='M10 4C8.07 4 6.5 5.57 6.5 7.5C6.5 9.43 8.07 11 10 11C11.93 11 13.5 9.43 13.5 7.5C13.5 5.57 11.93 4 10 4ZM10 9C9.17 9 8.5 8.33 8.5 7.5C8.5 6.67 9.17 6 10 6C10.83 6 11.5 6.67 11.5 7.5C11.5 8.33 10.83 9 10 9Z' fill='%23323232' fill-opacity='0.8'/%3E%3C/svg%3E%0A");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  a.mail {
    padding-left: 35px;
  }

  .profile,
  label.mail {
    &::before {
      left: 22px;
      top: 20px;
    }
  }

  label.mail {
    margin-bottom: rem(55);
  }

  .btn {
    padding: 17px;
    max-width: 225px;
  }

  @include media(mobile-m) {
    &__inner {
      padding: 20px;
    }

    h2 {
      font-size: 30px;
    }

    a.mail {
      padding-left: 0;

      &::before {
        display: none;
      }
    }
  }
}

section.contact {
  padding: rem(124) 0 rem(80);
}