@use "../../utils/functions/"as*;
@use "../../utils/mixins"as*;

.approach {
  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }

  li {
    border-left: 1px solid #0300223D;
    padding: 0 24px;
    width: 100%;
    max-width: 400px;

    h3 {
      margin-bottom: 20px;
      font-weight: var(--fw-400);
      letter-spacing: -0.05em;
    }

    p {
      line-height: 1.55;
      letter-spacing: 0.017em;
    }
  }

  .section-header {
    h2 {
      margin-bottom: 5px;
    }

    p {
      margin-bottom: rem(52);
    }
  }

  @include media(tablet) {
    &__grid {
      grid-template-columns: 1fr;
    }

    li {
      max-width: 100%;
    }
  }
}

section.approach {
  padding-bottom: rem(52);
}