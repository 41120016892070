/* stylelint-disable declaration-no-important */
/* stylelint-disable declaration-block-no-duplicate-properties */

@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.hidden {
  display: none !important;
}

.sr-only {
  position: absolute !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  overflow: hidden !important;
  margin: -1px !important;
  border: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  white-space: nowrap !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100dvh;
  height: calc(var(--vh, 1vh) * 100);
  overscroll-behavior: none;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.is-scrolling {
  scrollbar-color: var(--color-purple-400) var(--color-white);
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background-color: var(--color-white);
  }

  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background-color: var(--color-purple-400);
  }

  @include media(tablet-wide) {
    overflow-y: scroll;
    padding: 15px;
  }
}