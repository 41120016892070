@use "../../utils/functions/"as*;
@use "../../utils/mixins"as*;

.about-list {
  &__grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 30px;
  }

  &__item {
    position: relative;
    padding-top: rem(88);

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 64px;
      height: 64px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &--customer {
      &::before {
        background-image: url("../images/icons/customer.svg");
      }
    }

    &--innovation {
      &::before {
        background-image: url("../images/icons/innovation.svg");
      }
    }

    &--integrity {
      &::before {
        background-image: url("../images/icons/integrity.svg");
      }
    }

    &--collaboration {
      &::before {
        background-image: url("../images/icons/collaboration.svg");
      }
    }

    &--learning {
      &::before {
        background-image: url("../images/icons/learning.svg");
      }
    }
  }

  h3 {
    margin-bottom: 14px;
    font-weight: var(--fw-600);
    font-size: 18px;
    line-height: 1.2;
  }

  p {
    font-size: 14px;
  }

  @include media(tablet) {
    &__grid {
      grid-template-columns: repeat(2, 1fr);
    }

    &__item {
      &:last-child {
        grid-column: span 2;
      }
    }
  }

  @include media(mobile-m) {
    &__grid {
      grid-template-columns: 1fr;
    }

    &__item {
      &:last-child {
        grid-column: auto;
      }
    }
  }
}