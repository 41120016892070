@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.footer {
  padding: rem(42) 0 30px;
  color: var(--color-white);
  background-color: var(--color-purple-400);

  &__inner {
    display: flex;
    justify-content: space-between;
  }

  &__nav {
    translate: 0 -5px;

    li:not(:last-child) {
      margin-bottom: 10px;
    }

    a {
      line-height: 1;
    }
  }

  p {
    font-size: 16px;
  }

  .logo {
    margin-bottom: 11px;
  }

  .mail {
    position: relative;
    display: block;
    margin-bottom: rem(34);
    padding-left: 32px;
    font-size: 16px;

    &::before {
      content: "";
      position: absolute;
      left: 2px;
      top: 2px;
      width: 20px;
      height: 16px;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM18 14H2V4L10 9L18 4V14ZM10 7L2 2H18L10 7Z' fill='white' fill-opacity='0.6'/%3E%3C/svg%3E%0A");
      background-position: 0 0;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  @include media(mobile-l) {
    &__inner {
      flex-direction: column;
      align-items: center;

      >div {
        text-align: center;

        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }

    .logo {
      margin: 0 auto 20px;
    }

    .mail {
      margin: 0 auto 20px;
      max-width: max-content;
    }
  }
}