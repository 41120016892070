/* stylelint-disable alpha-value-notation */
/* stylelint-disable color-function-notation */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

:root {
  --content-width: 1244px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // font-family
  --font-family-primary: "Open Sans", sans-serif;

  // font-weight
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-800: 800;

  // color
  --color-white: #fff;
  --color-black: #030022;
  --color-dark: #03002266;
  --color-dark-100: #03002299;
  --color-white-100: #FFFFFF99;
  --color-purple-200: #6C5FFF99;
  --color-purple-400: #6C5FFF;
  --color-purple-600: #0C0094;


  //filter

  //  radius
  --radius-main: 2px;

  // shadow

  // gradient

  --gradient: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(0deg, rgba(108, 95, 255, 0.06), rgba(108, 95, 255, 0.06));

  --gradient-border: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 50.48%, rgba(255, 255, 255, 0) 100%);



  --arrow: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.125 10H16.875' stroke='white' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11.25 4.375L16.875 10L11.25 15.625' stroke='white' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}