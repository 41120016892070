@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.blog {
  padding: rem(80) 0;

  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: rem(49) 23px;
  }

  h2 {
    text-align: center;
  }

  @include media(tablet) {
    &__grid {
      grid-template-columns: repeat(2, 1fr);
    }

    .card {
      max-width: 100%;
    }
  }

  @include media(mobile-m) {
    &__grid {
      grid-template-columns: 1fr;
    }
  }
}