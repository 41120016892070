@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.card {
  max-width: 300px;

  img {
    overflow: hidden;
    margin-bottom: 15px;
    border-radius: var(--radius-main);
    width: 100%;
    max-height: 182px;
  }

  time,
  span {
    font-size: 14px;
    letter-spacing: 0.15em;
    color: var(--color-purple-200);
  }

  h3 {
    margin-bottom: 20px;
    font-size: 20px;
    letter-spacing: -0.02em;
  }

  a {
    font-size: 14px;
    letter-spacing: 0.02em;
    text-decoration: underline;
    color: var(--color-purple-400);
  }

  &--large {
    max-width: 100%;

    img {
      max-height: 100%;
    }

    span {
      display: block;
      margin-bottom: 15px;
    }

    ul {
      margin-bottom: rem(45);
      padding-left: 24px;

      li {
        list-style-type: disc;
        line-height: 1.47;
        color: var(--color-black);
        letter-spacing: 0.006em;
      }
    }

    p {
      font-weight: var(--fw-600);
      font-size: 18px;
      line-height: 1.57;
    }
  }
}