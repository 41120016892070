@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
  margin: 0 auto;
  border-bottom: 1px solid;
  border-image-source: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, var(--color-white) 50.48%, rgba(255, 255, 255, 0) 100%);
  border-image-slice: 1;
  padding: 24px 0;
  width: 100%;
  transition-property: background-color, transform, padding;
  transition-duration: 0.15s;

  &.hide {
    transform: translateY(calc(-100% - 30px));
  }

  &.scroll {
    background-color: var(--color-white);

    .logo {
      filter: brightness(0);
    }

    .nav__link {
      color: var(--color-black);

      &.true {
        color: var(--color-purple-400);
      }
    }

    .burger {
      --lines-color: var(--color-black);
    }
  }

  &.hide.scroll {
    transform: translateY(0);
  }

  &--secondary {
    color: var(--color-white);
    background-color: var(--color-purple-400);

    .nav__link.true {
      text-decoration: underline;
      color: var(--color-white);
    }

    button.formsapp-popup-button {
      border-color: var(--color-white) !important;
    }
  }
}