@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.offerings {
  $root: &;

  padding: rem(97) 0;

  &__tabs {
    display: flex;
    justify-content: center;
    margin-bottom: rem(35);

    li {
      &:not(:last-child) {
        margin-right: rem(44);
      }
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: rem(42) 24px;
  }

  .section-header {
    margin-bottom: rem(32);
  }

  button {
    padding: 0;
    font-weight: var(--fw-600);
    font-size: 20px;
    line-height: 1;
    white-space: nowrap;
    color: var(--color-purple-400);
    background-color: transparent;

    &:hover,
    &:focus {
      border-color: transparent;
    }

    &.active {
      border-color: transparent;
      font-weight: var(--fw-700);
      font-size: 24px;
    }
  }

  @include media(tablet) {
    &__grid {
      grid-template-columns: repeat(2, 1fr);
    }

    &__tabs {
      justify-content: flex-start;
    }
  }

  @include media(mobile-m) {
    &__grid {
      grid-template-columns: 1fr;
    }

    &__tabs {
      li {
        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }
  }
}