@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.choose {
  @include line;

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 28px;
  }

  h3 {
    margin-bottom: 18px;
    font-size: 18px;
    text-transform: uppercase;
  }

  img {
    margin-bottom: 22px;
  }

  @include media(tablet) {
    &__list {
      grid-template-columns: 1fr;
    }
  }
}