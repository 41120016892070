@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.hero {
  $root: &;

  display: flex;
  justify-content: center;
  background-position: 0 0;
  background-size: cover;
  background-repeat: no-repeat;

  &__inner {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    min-height: 100dvh;
    max-width: 1060px;
    text-align: center;
    color: var(--color-white);


    h1 {
      margin-bottom: 10px;
      padding-top: 12px;
    }

    p {
      font-weight: var(--fw-600);
      font-size: 24px;
    }
  }
}