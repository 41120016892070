@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.about {
  $root: &;

  &__inner {
    display: flex;
    align-items: center;
  }

  &__image {
    margin-right: 25px;
    width: 100%;
    max-width: 610px;
  }

  &__content {
    margin-right: auto;
    max-width: 595px;
  }

  h2 {
    margin-bottom: 30px;
  }

  p {
    line-height: 1.55;
  }

  &--reverse {
    #{$root}__inner {
      flex-direction: row-reverse;
    }

    #{$root}__image {
      margin-right: 17px;
    }

    h2 {
      margin-bottom: 8px;
    }
  }

  @include media(tablet) {

    &--reverse &__inner,
    &__inner {
      flex-direction: column;
    }

    &__image {
      margin: 0 auto 30px;
      max-width: 100%;

      img {
        aspect-ratio: 16 / 7;
        width: 100%;
      }
    }

    &__content {
      max-width: 100%;
    }

  }
}