@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.single {
  padding: calc(var(--header-height) + rem(76)) 0 rem(80);

  .container {
    max-width: 640px;
  }

  *:not(:last-child, .back, h1, time, li, h2+p) {
    margin-bottom: 24px;
  }

  h1 {
    margin-bottom: 13px;
    font-weight: var(--fw-700);
    font-size: clamp(1.625rem, 1.2536rem + 1.5238vi, 2.625rem);
    line-height: 1.2;
    letter-spacing: -0.01em;
    color: var(--color-black);
  }

  time {
    display: block;
    margin-bottom: rem(40);
    font-weight: var(--fw-700);
    font-size: 14px;
    letter-spacing: 2px;
    color: var(--color-purple-200);
  }

  p {
    font-weight: var(--fw-400);
    font-size: 16px;
    line-height: 1.64;
  }

  h2+p {
    margin-bottom: 25px;
  }

  ol {
    list-style: auto;
    padding-left: 22px;

    li {
      list-style: auto;

      &::marker {
        font-weight: var(--fw-700);
        color: var(--color-purple-400);
      }

      span {
        font-weight: var(--fw-700);
        color: var(--color-purple-400);
      }
    }
  }

  ul {
    padding-left: 22px;

    li {
      list-style-type: disc;
    }
  }

  h2 {
    font-size: 16px;
    color: var(--color-purple-400);
  }

  h2+ol,
  p+ol {
    li {
      &::marker {
        font-weight: var(--fw-400);
        color: inherit;
      }
    }
  }
}