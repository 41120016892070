@forward "backgrounds";
@forward "burger";
@forward "checkbox";
@forward "flexbox";
@forward "font-size";
@forward "footer-to-bottom";
@forward "hiding-text";
@forward "visibiity";

@mixin line {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    height: 1px;
    max-width: var(--content-width);
    background-color: #6C5FFF3D;
  }
}