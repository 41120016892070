@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

h1,
h2,
h3 {
  overflow: hidden;
  width: fit-content;
  line-height: 1.3;
}

h1 {
  line-height: 1.1;
  font-weight: var(--fw-800);
  font-size: clamp(2.125rem, 1.4286rem + 2.8571vi, 4rem);

  @include media(desktop-wide, min) {
    font-size: 64px;
  }
}

h2 {
  font-weight: var(--fw-700);
  font-size: clamp(1.6875rem, 1.3857rem + 1.2381vi, 2.5rem);
  color: var(--color-black);
}

h3 {
  font-weight: var(--fw-700);
  font-size: 20px;
  color: var(--color-black);
}

p {
  font-size: 18px;
}