/* stylelint-disable declaration-no-important */
@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

*[class].btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  border-radius: calc(var(--radius-main) * 50) !important;
  padding: 8px 15px !important;
  width: 100%;
  max-width: 130px;
  font-weight: var(--fw-600);
  font-size: 18px !important;
  line-height: 1.28;
  text-align: center;
  color: var(--color-black);
  background-color: var(--color-white);
  transition-property: background-color, color, border-color;
  transition-duration: 0.2s;

  &:hover,
  &:focus {
    border-color: var(--color-purple-400);
    color: var(--color-purple-400);
  }

  &--reverse {
    color: var(--color-white);
    background-color: var(--color-purple-400);

    &:hover,
    &:focus {
      border-color: var(--color-purple-400);
      color: var(--color-purple-400);
      background-color: var(--color-white);
    }
  }

  &--dark {
    color: var(--color-white);
    background-color: var(--color-purple-400);
  }

  &--arrow {
    &::after {
      content: "";
      flex-shrink: 0;
      margin-left: 8px;
      width: 20px;
      height: 20px;
      background-image: var(--arrow);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
}

button.formsapp-popup-button {
  margin: 0 !important;
  padding: 0 !important;
  font-family: var(--font-family-primary) !important;
  white-space: nowrap;
  line-height: 1 !important;
  color: var(--color-white) !important;
  background-color: var(--color-purple-400) !important;

  &:hover,
  &:focus {
    border-color: var(--color-white) !important;
  }
}